(function ($, site, prodcat) {
  // Let product.skuSelect handle initiation
  $(document).on('product.init', '.js-product.js-product-full-v2', function () {
    var $self = $(this);
    var $imageContainer = $('.js-product-full__image', $self);
    var $featureMediaObj = $();

    if ($imageContainer.length) {
      if (!$imageContainer.data().featureMediaLoaded) {
        $featureMediaObj = $imageContainer.find('.js-product-full__feature-media');
        $imageContainer.data('featureMediaObj', $featureMediaObj.html());
        $imageContainer.data('featureMediaLoaded', true);
      }
      $(document).trigger('product.fullImage.init', [$self]);
    }
  });

  $(document).on('product.skuSelect', '.js-product.js-product-full-v2', function (event, skuBaseId) {
    skuBaseId = parseInt(skuBaseId);

    var $product = $(this);
    var $imageContainer = $('.js-product-full__image', $product);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(skuBaseId);
    var currentSku = parseInt($imageContainer.attr('data-sku-base-id'));

    if (!$imageContainer.length) {
      return;
    }

    // Check against current sku | avoid rendering twice
    if (skuBaseId === currentSku && !$imageContainer.data('imageContainerLoaded')) {
      $imageContainer.data('imageContainerLoaded', true);

      return;
    }

    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;
    var $video, videoImage, $videoImageContainer, yt, ytId;
    var $videoContainer = $('.js-spp-carousel__slide--video', $imageContainer);
    var hasVideo = $videoContainer.length ? 1 : 0;
    var $featureMediaObj = $('.js-product-full__feature-media', $product);
    var featureMedia = $imageContainer.data().featureMediaObj || $featureMediaObj.html();

    // Feature media / first slide
    if (featureMedia) {
      data['product_featured_media'] = {
        html: featureMedia
      };
    }

    // Check for video
    if (hasVideo) {
      $video = $('.js-product-video', $videoContainer);
      $videoImageContainer = $('.js-video_image', $video);
      videoImage = $videoImageContainer.attr('src') || $videoImageContainer.attr('data-src');
      yt = !!$video.attr('data-video-provider');
      ytId = $video.attr('data-youtube-id');
      /**
       * Additional support will be needed for html5 and zentrick
       * provider-htmlfive
       * provider-zentrick
       * id
       * html
       */
      data['video'] = {
        videoImage: videoImage,
        'provider-youtube': yt,
        youtube_id: ytId
      };
      data['video_image'] = {
        src: videoImage
      };
    }

    // Check if shaded product & remove 2nd image
    if (data.PARENT_CAT_ID === "CAT5817" && data.IMAGE_LARGE.length === 3) {
      data.IMAGE_LARGE.splice(1, 1);
    }

    $imageContainer
      .fadeTo('slow', 0, 'linear', function () {
        var $self = $(this);
        // Sku Images
        var product_full_image = site.template.get({
          name: 'product_full_image_spp_v2',
          data: data
        });

        $(this).html($(product_full_image).html());

        // Reattach video
        if (hasVideo) {
          $(document).trigger('youtubeIframeAPI.loaded');
        }

        // Refresh the slick slider if there is one
        $(document).trigger('product.fullImage.init', [$product]);

        $(document).trigger('productflag.init', $imageContainer);

        // When Mantle video lazy load is disabled, reload video
        var $mantleMedia = $('.js-spp-carousel__slide:not(.slick-cloned) .mantle-media-asset', $self);
        var $mantleVideo = $mantleMedia.find('video');
        if ($mantleMedia.attr('data-load-behavior') === 'non-lazy') {
          $mantleVideo.load();
          $mantleVideo.get(0).play();
        }
      })
      .fadeTo('fast', 1, 'linear');
  });

  /**
   * Main handler for image carousels
   */
  $(document).on('product.fullImage.init', function (e, context = document) {
    $('.product-full__image', context).each(function () {
      var $container = $(this);
      var $pContainer = $container.parents('.js-product-full-v2');
      var $sppImages = $('.product-full__image-carousel', $container);
      var $carousel = $('.js-spp-carousel-v2', $sppImages);
      var dotsDiv = $('.carousel-dots', $container);
      var $sppSlides = $pContainer.find('.js-spp-carousel__slide');
      var sppSlide = '.js-spp-carousel__slide';
      // Case for custom slides count and dots for mobile
      var settings = {
        slide: sppSlide,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        appendDots: dotsDiv
      };

      var _init_default_carousel = function () {
        var $carousels = $carousel.not('.slick-initialized');
        var $mCarousel = $pContainer.find($carousels);

        if ($carousels.length === 0) {
          return;
        }

        $carousel.on('init', function (event, slick) {
          var $videoSlide = $carousel.find('.js-spp-carousel__slide--video:not(".slick-cloned")');
          var videoSlideIndex = $videoSlide.data('slick-index');
          var $slickDotsItems = dotsDiv.find('.slick-dots li');

          if ($videoSlide.length) {
            dotsDiv.find('.slick-dots li').eq(videoSlideIndex).addClass('slick-dot-video');
          } else {
            $slickDotsItems.addClass('slick-dot-item');
            if ($slickDotsItems.length === 2) {
              $slickDotsItems.addClass('two-slides-carousel');
            }
            dotsDiv.find('.slick-dots button').addClass('slick-dot-button');
          }
        });

        // Init this carousel with our settings
        // $carousel.not('.slick-initialized').slick(settings);
        $carousels.slick(settings);

        $carousel.on('afterChange', function (event, slick, currentSlide) {
          // Update the thumbnails to highlight the correct thumbnail
          var $flagAndSocialElements = $(
            '.product-full__social, .product-flag__badge',
            '.product-full'
          );

          // Hide the product flag and share element when video slide is in view
          if ($(slick.$slides.get(currentSlide)).hasClass('js-spp-carousel__slide--video')) {
            $flagAndSocialElements.hide();
          } else {
            $flagAndSocialElements.show();
          }
        });
        $(window).on('load resize', _.throttle(function () {
          var _checkDesktopView = function () {
            var cpgw = Unison.fetch.now();
            var pgw = Unison.fetch.all();
            return parseInt(cpgw.width, 10) >= parseInt(pgw.landscape, 10);
          };

          if (_checkDesktopView()) {
            $mCarousel.slick('slickFilter', $sppSlides.last());
          } else {
            $mCarousel.slick('slickUnfilter');
          }
        }, 100));
      };

      _init_default_carousel();

    });
  });
})(jQuery, window.site || {}, window.prodcat || {});
